import React from 'react';
import { Player } from '@lottiefiles/react-lottie-player';
import AnimationOne from './myInterview Homepage Animation_1.json';
import AnimationTwo from './myInterview Homepage Animation_2.json';
import '../website.scoped.scss';

export const SectionSteps = () => (
  <section id="steps">
    <div className="container py-5 align-items-center">
      <div className="row">
        <div className="col-lg-5 pe-md-0">
          <h2
            className="font-weight-bolder text-uppercase text-panther display-6 mb-4"
          >
            Hire Smarter
          </h2>
          <p className="text-panther lead line-height-1-2 mb-5">
            With myInterview Intelligence™, your queue is always full of
            custom-picked candidates to watch. Purpose-built machine learning
            algorithms fueled by diverse data scan every video to reveal the
            hidden gems that perfectly match what you’re looking for. Welcome
            to the future of hiring.
          </p>
          <div className="hire-smarter-list">
            <div className="list-item">
              <h2>Curated Interview Playlists</h2>
              <p className="lead">
                With Automated Shortlisting, you uncover the most relevant
                candidates and put them center-stage.
              </p>
              <Player
                className="d-block d-lg-none"
                autoplay
                loop
                src={AnimationOne}
              />
            </div>
            <div className="toggle-spacer" />
            <div className="list-item">
              <h2>Bias-Free Assessments</h2>
              <p className="lead">
                Each video is analyzed for soft skills, personality traits,
                and keywords so you can tell which candidates match your
                company vibe, while reducing the risk of bias from entering
                the picture.
              </p>
              <Player
                className="d-block d-lg-none"
                autoplay
                loop
                speed="1"
                src={AnimationTwo}
              />
            </div>
          </div>
        </div>
        <div className="col-lg-7">
          <div className="d-none d-lg-block">
            <Player
              id="img-1"
              autoplay
              loop
              speed="1"
              src={AnimationOne}
            />
          </div>
        </div>
      </div>
    </div>
  </section>
);
