import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';
import './swiper.scoped.scss';

import SwiperCore, { Autoplay } from 'swiper';
import React from 'react';

import logoSalesforce from '../../theme/pictures/cl-3.png';
import logoAxxon from '../../theme/pictures/cl-4.png';
import logoBatyr from '../../theme/pictures/cl-5.png';
import logoBiffa from '../../theme/pictures/cl-6.png';
import logoCatch from '../../theme/pictures/cl-7.png';
import logoSixflags from '../../theme/pictures/cl-12.png';
import logoMondelez from '../../theme/pictures/cl-14.png';
import logoMcdonalds from '../../theme/pictures/cl-15.png';
import logoBm from '../../theme/pictures/cl-17.png';
import logoAgoda from '../../theme/pictures/cl-18.png';
// import Techcrunch from '../../theme/pictures/website/logos/techcrunch_logo.png';
// import Forbes from '../../theme/pictures/website/logos/forbes_logo.png';

SwiperCore.use([Autoplay]);

const config = {
  spaceBetween: 80,
  loop: true,
  speed: 3500,
  autoplay: {
    delay: 0,
    disableOnInteraction: false,
  },
  breakpoints: {
    320: {
      slidesPerView: 3,
      spaceBetween: 15,
    },
    992: {
      slidesPerView: 4,
      spaceBetween: 30,
    },
    1200: {
      slidesPerView: 5,
      spaceBetween: 30,
    },
  },
};

export const SwiperSlider = () => (
  <Swiper
    {...config}
  >
    <SwiperSlide><img src={logoSalesforce} alt="Salesforce" /></SwiperSlide>
    <SwiperSlide><img src={logoAxxon} alt="Axxon" /></SwiperSlide>
    <SwiperSlide><img src={logoBatyr} alt="Batyr" /></SwiperSlide>
    <SwiperSlide><img src={logoBiffa} alt="Biffa" /></SwiperSlide>
    <SwiperSlide><img src={logoCatch} alt="Catch" /></SwiperSlide>
    <SwiperSlide><img src={logoSixflags} alt="Six Flags" /></SwiperSlide>
    <SwiperSlide><img src={logoMondelez} alt="Mondelez" /></SwiperSlide>
    <SwiperSlide><img src={logoMcdonalds} alt="McDonalds'" /></SwiperSlide>
    <SwiperSlide><img src={logoBm} alt="B&M" /></SwiperSlide>
    <SwiperSlide><img src={logoAgoda} alt="Agoda" /></SwiperSlide>
  </Swiper>
);
