import React from 'react';
import './header.scoped.scss';
import { useLocation } from 'react-router-dom';
import MetaTags from 'react-meta-tags';
import logo from '../../theme/pictures/mi-logo.svg';
import { tryNowClicked } from '../../pages/website/components/sweetAlertComp/sweetAlert';

export const Header = () => {
  const location = useLocation();
  if (location.pathname === '/website') {
    return (
      <header className="header-website">
        <MetaTags>
          <title>myInterview - Supercharge your hiring with video. Free. Forever.</title>
          <meta name="robots" content="noindex" />
          <meta name="AdsBot-Google" content="noindex" />
          )
        </MetaTags>
        <nav className="navbar navbar-expand-lg navbar-light">
          <div className="container">
            <div className="navbar-main">
              <div className="logo">
                <img src={logo} alt="Logo" />
              </div>
            </div>
            <div className="navbar-menu">
              <div className="buttonElem d-none d-lg-block">
                <li className="nav-item px-lg-3 header-cta">
                  <button className="btn btn-primary btn-try" onClick={tryNowClicked}>Try it for Free</button>
                </li>
              </div>
            </div>
          </div>
        </nav>
      </header>
    );
  }
  return (
    <header>
      <div className="container">
        <div className="logo">
          <img src={logo} alt="Logo" />
        </div>
      </div>
    </header>
  );
};
