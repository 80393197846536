import React from 'react';
import ELL36 from '../../../theme/pictures/website/Ellipse36.svg';
import ELL37 from '../../../theme/pictures/website/Ellipse37.svg';
import InterviewCrtoon from '../../../theme/pictures/website/Interview-Crtoon.png';
import Model from '../../../theme/pictures/website/model.png';
import '../website.scoped.scss';

export const SectionCta = () => (
  <section id="cta" className="pt-5 pt-lg-0">
    <div className="position-absolute cta-animated-1">
      <div data-aos="fade-down">
        <img src={ELL36} alt="" />
      </div>
    </div>
    <div className="position-absolute cta-animated-2">
      <div data-aos="fade-left">
        <img src={ELL37} alt="" />
      </div>
    </div>
    <div className="position-absolute cta-static-icon">
      <img src={InterviewCrtoon} alt="" />
    </div>
    <div className="container py-5">
      <div className="row align-items-center">
        <div className="col-8 mx-auto col-lg-4 offset-lg-1 order-last order-lg-first">
          <img src={Model} className="img-fluid d-block" alt="Model" loading="lazy" />
        </div>
        <div className="col-lg-6 offset-lg-1">
          <h2 className="text-white font-weight-bolder text-uppercase display-6">Always Free. Forever.</h2>
          <p className="text-white lead">
            No trial period, no expiration date. Hiring for more than one job? Just
            upgrade to a paid plan, and downgrade to free whenever you like.
          </p>
        </div>
      </div>
    </div>
  </section>
);
