import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import G2 from '../../../theme/pictures/website/G2.png';
import Hackerexchange from '../../../theme/pictures/website/hackerexchange_logo.png';
import Catch from '../../../theme/pictures/website/catch.svg';
import Capterra from '../../../theme/pictures/website/capterra.png';
import '../website.scoped.scss';

export const SectionTestimonials = () => {
  const config = {
    centeredSlides: true,
    loop: true,
    autoplay: {
      delay: 10000,
      disableOnInteraction: true
    },
    pagination: {
      el: '.swiper-pagination',
      clickable: true
    },
    slideToClickedSlide: true,
    keyboard: true,
    breakpoints: {
      640: {
        slidesPerView: 1
      },
      768: {
        slidesPerView: 1
      },
      1024: {
        slidesPerView: 2
      },
      1440: {
        slidesPerView: 3
      }
    }
  };

  return (
    <section id="testimonials">
      <div className="container-fluid py-5">
        <div id="testimonials-carousel" className="swiper-container">
          <Swiper
            {...config}
          >
            <SwiperSlide>
              <div className="bg-white w-50 mx-auto position-relative p-4 text-center border-20 testimonial-arrow">
                <a href="#/lol">
                  <img src={G2} alt="" loading="lazy" />
                </a>
              </div>
              <div className="mi-bg-gradient testimonial-card border-30 text-white text-center">
                <p className="testimonial-title">Great for a small organization looking to simplify hiring!</p>
                <p className="test-text">
                  “We found MyInterview in early 2019 after searching for the
                  best way to evaluate candidates without spending HOURS in
                  in-person meetings. It’s allowed us to free up so much time
                  and narrow down the pool much faster. myInterview support has
                  been nothing but helpful from Day 1. I highly recommend them!
                  I love this tool!”
                </p>
                <p className="mb-0 testimonial-name">
                  Meggie S -
                  <a
                    href="#/lol"
                  >
                    via G2
                  </a>
                </p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="bg-white w-50 mx-auto position-relative p-4 text-center border-20 testimonial-arrow">
                <img src={Hackerexchange} alt="" loading="lazy" />
              </div>
              <div className="mi-bg-gradient testimonial-card border-30 text-white text-center">
                <p className="testimonial-title">Invaluable</p>
                <p className="test-text">
                  “MyInterview has been invaluable for The Hacker Exchange to
                  efficiently and professionally facilitate our application
                  process following our increased growth in numbers. The
                  software has enabled our company to maintain an equitable
                  interview process and has given us deeper insight into our
                  applicants, making our programs more diverse and competitive.”
                </p>
                <p className="mb-0 testimonial-name">Chris Hoffmann - The Hacker Exchange</p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="bg-white w-50 mx-auto position-relative p-4 text-center border-20 testimonial-arrow">
                <a href="#/lol">
                  <img src={G2} alt="" loading="lazy" />
                </a>
              </div>
              <div className="mi-bg-gradient testimonial-card border-30 text-white text-center">
                <p className="testimonial-title">Saves Time & Money</p>
                <p className="test-text">
                  “This is a great way to screen potential candidates. My team
                  and I like that we are able to utilize video screening to move
                  through our recruiting process more efficiently. It also seems
                  to engage the candidates much quicker, rather than trying to
                  reach them via phone for a phone screening. We are very happy
                  with this program. Thank You!!”
                </p>
                <p className="mb-0 testimonial-name">
                  Bryant R -
                  <a
                    href="#/lol"
                  >
                    via G2
                  </a>
                </p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="bg-white w-50 mx-auto position-relative p-4 text-center border-20 testimonial-arrow">
                <img src={Catch} alt="" loading="lazy" />
              </div>
              <div className="mi-bg-gradient testimonial-card border-30 text-white text-center">
                <p className="testimonial-title">We hired 40 new people in 15 days</p>
                <p className="test-text">
                  “myInterview enables our project team to quickly review and
                  determine whether the candidates have the right mix of energy,
                  positivity and strong customer service skills. It was
                  imperative that we had a central source to quickly review
                  candidates and move through to the next stage of the process.
                  To that end the solution enabled us to appoint 40 staff
                  members in 15 days...”
                </p>
                <p className="mb-0 testimonial-name">Ben Cartland - Catch</p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="bg-white w-50 mx-auto position-relative p-4 text-center border-20 testimonial-arrow">
                <a
                  href="#/lol"
                  aria-label="Testimonial Capterra"
                >
                  <img src={Capterra} alt="Capterra logo" loading="lazy" />
                </a>
              </div>
              <div className="mi-bg-gradient testimonial-card border-30 text-white text-center">
                <p className="testimonial-title">Great platform, wonderful people</p>
                <p className="test-text">
                  “We wanted to create a more fair and collaborative process and
                  at the same time implement efficiencies which save time for
                  all involved. Everyone on the interview panel can review the
                  interviews on the go and we can make better decisions on who
                  we invite to interview based on ability, attitude and fit for
                  the role. The ease of use and simplicity of the platform is
                  great.”
                </p>
                <p className="mb-0 testimonial-name">
                  Ben S. -
                  <a
                    href="#/lol"
                  >
                    via Capterra
                  </a>
                </p>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </section>
  );
};
