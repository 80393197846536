import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { Header, Footer } from './components';
import DemoPlease from './pages/demoplease';
import BookADemo from './pages/bookademo';
import Website from './pages/website';
import Form from './pages/form';
import Data from './data.json';

const NotFoundRedirect = () => <Redirect to="/" />;

const renderAppRoutes = () => (
  <Switch>
    <Route exact path="/" render={() => <Redirect to="/demoplease" />} />
    <Route exact path="/demoplease" component={() => <DemoPlease buttonAction="calendly" />} />
    <Route exact path="/bookademo" component={() => <BookADemo buttonAction="redirect" />} />
    <Route exact path="/website" component={() => <Website />} />
    <Route exact path="/form" component={() => <Form />} />
    <Route exact path="/admin" component={() => <DemoPlease buttonAction="calendly" />} />
    <Route component={NotFoundRedirect} />
  </Switch>
);
const App = () => (
  <div className="App">
    <Header />
    {renderAppRoutes()}
    <Footer data={Data.footer[0]} />
  </div>
);

export default App;
