import React from 'react';
import { SwiperSliderTwo } from './SectionThree';
import '../website.scoped.scss';

export const SectionPartners = () => (
  <section className="pt-5 pt-lg-0">
    <div className="container py-5">
      <div className="row align-items-center">
        <div className="col-lg-1 position-relative">
          <h3 className="font-family-caveat font-weight-bold text-apple arrow-after-down display-6">Partners</h3>
        </div>
        <div className="col-lg-10 offset-lg-1">
          <div id="partners-carousel">
            <SwiperSliderTwo />
          </div>
        </div>
      </div>
      <div className="spacer-mobile d-block d-lg-none" />
    </div>
  </section>
);
