/* eslint-disable no-console */
/* eslint-disable camelcase */
import axios from 'axios';
import country_codes from './country_codes';

export const getUtmParams = () => {
  const urlSplit = window.location.search.substring(1); // get rid of "?" in querystring
  const qArray = urlSplit.split('&');
  let out = ''; // get key-value pairs
  for (let i = 0; i < qArray.length; i += 1) {
    out += `${qArray[i]}&`;
  }
  if (out.length && out.substring(out.length - 1) === '&') {
    out = out.substring(0, out.length - 1);
  }
  return out;
};

export const getCountryCode = () => axios.get('https://api.db-ip.com/v2/pf64ec43b31c34c63576b037e3c74c09275692cf/self/countryCode').then((resp) => {
  const countryCode = resp && resp.data;
  const calendly_url = country_codes.countries[countryCode] ? country_codes.countries[countryCode] : country_codes.countries.US;
  return calendly_url;
}).catch((error) => {
  console.log('error', error);
  return 'https://calendly.com/myinterview/request-a-demo-with-our-dream-team-us';
});
