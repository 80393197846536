import React from 'react';
import './section-one.scoped.scss';
import PropTypes from 'prop-types';
// import mascotDuo from '../../theme/pictures/mascot-salute-two.png';
import { Button } from '../button';

export const SectionOne = ({ handleButton, data }) => (
  <div id="section-one">
    <div className="container">
      <div className="row">
        <div className="block-main col-12 col-lg-6">
          <h1 className="text-gradient" dangerouslySetInnerHTML={{ __html: data.title }} />

          <div className="main-desc" dangerouslySetInnerHTML={{ __html: data.main_desc }} />
          <p dangerouslySetInnerHTML={{ __html: data.main_desc_p }} />
          <img src={data.mascotDuo} alt="Video Icon" className="mascots" />
        </div>
        <div className="block-cta-top col-12 col-lg-6">
          <div className="mi-bg-gradient">
            <div className="block-light">
              <div className="block-title" dangerouslySetInnerHTML={{ __html: data.block_title }} />
              <div className="h1 text-gradient" dangerouslySetInnerHTML={{ __html: data.h1_text_gradient }} />
              <Button className="mt-3 btn-cta" text={data.button_first} handleClick={() => handleButton('first')} />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

SectionOne.defaultProps = {
  handleButton: () => {},
  data: {},
};
SectionOne.propTypes = {
  handleButton: PropTypes.func,
  data: PropTypes.objectOf(PropTypes.any),
};
