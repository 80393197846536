import React from 'react';
import PlayBtn from '../../../theme/pictures/website/play-btn.svg';
import MP42 from '../../../theme/pictures/website/2.mp4';
import MP43 from '../../../theme/pictures/website/3.mp4';
import MP41 from '../../../theme/pictures/website/1.mp4';
import '../website.scoped.scss';

export const SectionVideo = () => (
  <section id="video">
    <div className="container py-5">
      <div className="row align-items-center">
        <div className="col-lg-5">
          <h2
            className="font-weight-bolder text-uppercase text-panther display-6 mb-4"
          >
            Tune-In Together
          </h2>
          <p className="text-panther lead mb-5 mb-lg-0">
            Watch as a team, so everyone’s on the same page. Easily share
            candidate videos with division managers, co-workers and even
            recruiting clients, that way exchanging opinions and feedback is
            as simple as pressing play.
          </p>
        </div>
        <div className="col-lg-5 offset-lg-2">
          <div className="row align-items-center">
            <div className="col-md-6 order-last order-lg-first">
              <div className="video-1 mb-4 mb-lg-5">
                <div className="video-card">
                  <button
                    type="button"
                    className="btn btn-play"
                    name="play video 1 icon"
                    aria-label="play video 1 icon"
                  >
                    <img src={PlayBtn} width="35" alt="" />
                  </button>
                  <video width="100%" autoPlay loop muted playsInline>
                    <source src={MP42} type="video/mp4" />
                    Your browser does not support HTML video.
                  </video>
                </div>
                <h3 className="font-family-caveat txt-panther text-center font-weight-bold mt-2">Julia</h3>
              </div>
              <div className="video-2">
                <div className="video-card">
                  <button
                    type="button"
                    className="btn btn-play"
                    name="play video 2 icon"
                    aria-label="play video 2 icon"
                  >
                    <img src={PlayBtn} width="35" alt="" />
                  </button>
                  <video width="100%" autoPlay loop muted playsInline>
                    <source src={MP43} type="video/mp4" />
                    Your browser does not support HTML video.
                  </video>
                </div>
                <h3 className="font-family-caveat txt-panther text-center font-weight-bold mt-2">Lucy</h3>
              </div>
            </div>
            <div className="col-md-6">
              <div className="video-3 mb-4 mb-lg-0">
                <div className="video-card">
                  <button
                    type="button"
                    className="btn btn-play"
                    name="play video 3 icon"
                    aria-label="play video 3 icon"
                    data-toggle="modal"
                    data-target="#videoModal"
                    data-video="https://www.youtube.com/embed/G1HxxFwtCRI"
                  >
                    <img src={PlayBtn} width="35" alt="" />
                  </button>
                  <video width="100%" autoPlay loop muted playsInline>
                    <source src={MP41} type="video/mp4" />
                    Your browser does not support HTML video.
                  </video>
                </div>
                <h3 className="font-family-caveat txt-panther text-center font-weight-bold mt-2">Tom</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);
