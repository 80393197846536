import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';

import SwiperCore, { Autoplay } from 'swiper';
import React from 'react';

import one from '../../../theme/pictures/website/facebook.png';
import two from '../../../theme/pictures/website/cl-2.png';
import three from '../../../theme/pictures/website/cl-7.png';
import four from '../../../theme/pictures/website/cl-9.png';
import five from '../../../theme/pictures/website/cl-6.png';
import six from '../../../theme/pictures/website/cl-12.png';
import seven from '../../../theme/pictures/website/ocado-group.png';
import eight from '../../../theme/pictures/website/cl-14.png';
import nine from '../../../theme/pictures/website/cl-15.png';
import ten from '../../../theme/pictures/website/cl-16.png';
// import eleven from '../../../theme/pictures/website/cl-17.png';
// import twelve from '../../../theme/pictures/website/cl-18.png';
import '../website.scoped.scss';

SwiperCore.use([Autoplay]);

const config = {
  spaceBetween: 80,
  loop: true,
  speed: 3500,
  autoplay: {
    delay: 0,
    disableOnInteraction: false,
  },
  breakpoints: {
    320: {
      slidesPerView: 3,
      spaceBetween: 15,
    },
    992: {
      slidesPerView: 4,
      spaceBetween: 30,
    },
    1200: {
      slidesPerView: 5,
      spaceBetween: 30,
    },
  },
};

export const SwiperSlider = () => (
  <Swiper
    {...config}
  >
    <SwiperSlide><img src={one} alt="Salesforce" /></SwiperSlide>
    <SwiperSlide><img src={two} alt="Axxon" /></SwiperSlide>
    <SwiperSlide><img src={three} alt="Batyr" /></SwiperSlide>
    <SwiperSlide><img src={four} alt="Biffa" /></SwiperSlide>
    <SwiperSlide><img src={five} alt="Catch" /></SwiperSlide>
    <SwiperSlide><img src={six} alt="Six Flags" /></SwiperSlide>
    <SwiperSlide><img src={seven} alt="Mondelez" /></SwiperSlide>
    <SwiperSlide><img src={eight} alt="McDonalds'" /></SwiperSlide>
    <SwiperSlide><img src={nine} alt="B&M" /></SwiperSlide>
    <SwiperSlide><img src={ten} alt="Agoda" /></SwiperSlide>
  </Swiper>
);
