import PhoneNumber from 'awesome-phonenumber';

const validateEmail = (email) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const validationFields = (details, countyCode) => {
  const detailsError = Object.entries(details).reduce((acc, detail) => {
    switch (detail[0]) {
      case 'username':
        if (!detail[1]) acc.username = 'This field cannot be blank';
        else if (detail[1].length < 3) acc.username = 'Please enter full name';
        break;
      case 'email':
        if (!detail[1]) acc.email = 'This field cannot be blank';
        else if (!validateEmail(detail[1])) {
          acc.email = "This doesn't look like a valid email address. Please try again.";
        }
        break;
      case 'phoneNumber':
        if (!detail[1]) acc.phoneNumber = 'This field cannot be blank';
        else if (!new PhoneNumber(details.phoneNumber, countyCode).isValid()) {
          acc.phoneNumber = 'Enter a valid phone number';
        }
        break;
      case 'name':
        if (!detail[1]) acc.name = 'This field cannot be blank';
        else if (detail[1].length < 3) acc.name = 'Please enter company name (3 characters min)';
        break;

      default:
        if (!detail[1]) acc[detail[0]] = 'This field cannot be blank';
        break;
    }
    return acc;
  }, {});

  return Object.keys(detailsError).length ? detailsError : null;
};
