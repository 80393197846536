import React, { useEffect, useState, useRef } from 'react';
import './section-one.scoped.scss';
import PropTypes from 'prop-types';
import 'react-phone-input-2/lib/style.css';
import PhoneInput from 'react-phone-input-2';
import mascotDuo from '../../theme/pictures/mascot-salute-two.png';
import { Button } from '../button';
import { sendForm } from '../../mixin/send-form';
import starShield from '../../theme/pictures/star-shield.svg';
import starValid from '../../theme/pictures/star-check.svg';
import freeSvg from '../../theme/pictures/free.svg';
import loveSvg from '../../theme/pictures/love.svg';
import supportSvg from '../../theme/pictures/support.svg';
import starChat from '../../theme/pictures/star-support.svg';
import employer from '../../theme/pictures/employer-1.png';
import logoPoferries from '../../theme/pictures/logo-poferries.png';
import arrowDown from '../../theme/pictures/arrow-down.png';
import { validationFields } from '../../mixin/validation-service';
import { getCountryCode as _getCountryCode } from '../../mixin/get-country-code';

const { log } = console;
const defaultFormDetails = {
  username: '',
  email: '',
  phoneNumber: '',
  name: '',
  leadStatus: '',
  shareThought: '',
  functionality: '',
  begin: ''
};

export const SectionOneForm = ({ data }) => {
  const [details, setDetails] = useState(defaultFormDetails);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [countryCode, setCountryCode] = useState('us');
  const [formErrors, setFormErrors] = useState(null);
  const isSubmited = useRef(false);
  const errorsToShow = useRef(new Set());
  const titleRef = useRef();
  const [successMessage, setSuccessMessage] = useState(false);

  useEffect(() => {
    _getCountryCode().then((res) => {
      setCountryCode(res?.toLowerCase() || 'us');
    });
  }, []);

  useEffect(() => {
    if (!isSubmited.current) {
      const relevantDetails = {};
      errorsToShow.current.forEach((key) => {
        relevantDetails[key] = details[key];
      });
      setFormErrors(validationFields(relevantDetails, countryCode));
    } else setFormErrors(validationFields(details, countryCode));
  }, [details, countryCode]);

  const handleValidation = ({ target }) => {
    const relevantDetails = { ...details };
    const name = target.getAttribute('data-name');
    errorsToShow.current.add(name);
    if (!isSubmited.current) {
      Object.keys(details).forEach((key) => {
        if (!details[key]) delete relevantDetails[key];
      });
      setFormErrors(validationFields(relevantDetails, countryCode));
      return;
    }
    setFormErrors(validationFields(details, countryCode));
  };

  const handleChange = ({ target }) => {
    const name = target.getAttribute('data-name');
    const { value } = target;
    setDetails((prev) => ({ ...prev, [name]: value }));
  };

  const handlePhoneNumberChange = (number, country) => {
    setCountryCode(country.countryCode);
    setDetails((prev) => ({ ...prev, phoneNumber: number }));
  };

  const scrollToTitle = () => {
    const scrollInterval = setInterval(() => {
      const distanceFromTop = titleRef.current.getBoundingClientRect().top;
      if (distanceFromTop >= 0) {
        clearInterval(scrollInterval);
      } else {
        window.scrollBy(0, distanceFromTop / 40);
      }
    }, 0);
  };

  const handleSubmit = async () => {
    setError('');
    setSuccessMessage(false);
    isSubmited.current = true;
    const updatedDetails = { ...details };
    setDetails(updatedDetails);
    const formValidationError = validationFields(updatedDetails, countryCode);
    setFormErrors(formValidationError);
    if (formValidationError) return;
    setIsLoading(true);
    try {
      await sendForm(details);
      setSuccessMessage(true);
    } catch (err) {
      setError('Something went wrong... please try again');
      log(err);
    } finally {
      setIsLoading(false);
      scrollToTitle();
    }
  };

  const getActiveClass = (detailName) => {
    if (formErrors && formErrors[detailName]) return 'has-error';
    return '';
  };

  return (
    <div id="section-one">
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-6">
            <div className="block-main">
              <div className="row">
                <div className="col-12 block-header">
                  <h1 className="text-gradient" dangerouslySetInnerHTML={{ __html: data.title }} />

                  <div className="main-desc" dangerouslySetInnerHTML={{ __html: data.main_desc }} />
                  <img src={mascotDuo} alt="Video Icon" className="mascots d-none" />
                </div>
                <div className="block-great col-12 d-none d-lg-block">
                  <div className="block-content">
                    <div className="h2" dangerouslySetInnerHTML={{ __html: data.block_great_h2 }} />
                    <div className="great-content">
                      <div className="great-row">
                        <div className="great-col-img">
                          <img src={freeSvg} alt="Free Forever" />
                        </div>
                        <div className="great-col-text">
                          <div
                            className="great-title"
                            dangerouslySetInnerHTML={{
                              __html: data.great_title
                            }}
                          />
                          <div
                            className="great desc"
                            dangerouslySetInnerHTML={{
                              __html: data.great_desc
                            }}
                          />
                        </div>
                      </div>
                      <div className="great-row">
                        <div className="great-col-img">
                          <img src={loveSvg} alt="Candidates love it" />
                        </div>
                        <div className="great-col-text">
                          <div
                            className="great-title"
                            dangerouslySetInnerHTML={{
                              __html: data.great_title_2
                            }}
                          />
                          <div
                            className="great desc"
                            dangerouslySetInnerHTML={{
                              __html: data.great_desc_2
                            }}
                          />
                        </div>
                      </div>
                      <div className="great-row">
                        <div className="great-col-img">
                          <img src={supportSvg} alt="Awesome Support" />
                        </div>
                        <div className="great-col-text">
                          <div
                            className="great-title"
                            dangerouslySetInnerHTML={{
                              __html: data.great_title_3
                            }}
                          />
                          <div
                            className="great desc"
                            dangerouslySetInnerHTML={{
                              __html: data.great_desc_3
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="block-people col-12 d-none d-lg-block">
                  <div className="block-content">
                    <div className="h2" dangerouslySetInnerHTML={{ __html: data.h2 }} />
                    <div className="people-img mi-bg-gradient">
                      <img src={employer} alt="Employer" className="img-employer" />
                      <img src={logoPoferries} alt="P&O Ferries logo" className="img-logo-poferries" />
                      <img src={arrowDown} alt="Arrow Down" className="img-arrow-down" />
                    </div>
                    <p className="quote" dangerouslySetInnerHTML={{ __html: data.quote }} />
                    <div className="author" dangerouslySetInnerHTML={{ __html: data.author }} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="block-cta-top col-12 col-lg-6">
            <div className="mi-bg-gradient mi-bg-gradient-soft">
              <div className="block-light">
                <div className="block-title" dangerouslySetInnerHTML={{ __html: data.block_title }} />
                <div
                  ref={titleRef}
                  className="h1 text-gradient"
                  dangerouslySetInnerHTML={{ __html: data.h1_text_gradient }}
                />
                <form
                  action=""
                  className={`mt-5 ${successMessage ? 'sent-successfuly' : ''}`}
                  onSubmit={handleSubmit}
                >
                  <div className="disable-screen" />
                  <div className="form-error form-error-global mb-4">{error}</div>
                  <div className={`form-item ${getActiveClass('username')}`}>
                    <label htmlFor="form-name">
                      <input
                        type="text"
                        id="form-name"
                        placeholder=" "
                        name="name"
                        data-name="username"
                        disabled={successMessage}
                        value={details.username}
                        onChange={handleChange}
                        onBlur={handleValidation}
                      />
                      <span className="form-label">Full name</span>
                    </label>
                    <div className="form-error">{formErrors?.username}</div>
                  </div>
                  <div className={`form-item ${getActiveClass('email')}`}>
                    <label htmlFor="form-email">
                      <input
                        type="email"
                        id="form-email"
                        placeholder=" "
                        name="email"
                        data-name="email"
                        disabled={successMessage}
                        value={details.email}
                        onChange={handleChange}
                        onBlur={handleValidation}
                        onInvalid={(e) => {
                          e.target.messageElement.style.display = 'none';
                        }}
                      />
                      <span className="form-label">Work email</span>
                    </label>
                    <div className="form-error">{formErrors?.email}</div>
                  </div>
                  <div className={`form-item ${getActiveClass('phoneNumber')}`}>
                    <PhoneInput
                      country={countryCode}
                      value={details.phoneNumber}
                      onChange={handlePhoneNumberChange}
                      onBlur={handleValidation}
                    // inputStyle={{ paddingLeft: '100px' }}
                    // buttonStyle={{
                    //   width: '90px',
                    //   display: 'flex',
                    //   justifyContent: 'center',
                    //   flexDirection: 'row',
                    //   flex: 1
                    // }}
                    // dropdownStyle={{ transform: 'translate(-20px, -5px)' }}
                    />
                    <div className="form-error">{formErrors?.phoneNumber}</div>
                  </div>
                  <div className={`form-item ${getActiveClass('name')}`}>
                    <label htmlFor="form-company">
                      <input
                        type="text"
                        id="form-company"
                        placeholder=" "
                        name="company"
                        data-name="name"
                        disabled={successMessage}
                        value={details.name}
                        onChange={handleChange}
                        onBlur={handleValidation}
                      />
                      <span className="form-label">Company name</span>
                    </label>
                    <div className="form-error">{formErrors?.name}</div>
                  </div>
                  <div className={`form-item form-radio mt-4 ${getActiveClass('leadStatus')}`}>
                    <div className="label-group">I am hiring for:</div>

                    <div className="material-radio">
                      <label htmlFor="inhouse">
                        <input
                          type="radio"
                          name="leadStatus"
                          data-name="leadStatus"
                          disabled={successMessage}
                          id="inhouse"
                          value="employer"
                          onChange={handleChange}
                          onBlur={handleValidation}
                        />
                        <span className="label-text">My Company</span>
                      </label>
                    </div>
                    <div className="material-radio">
                      <label htmlFor="agency">
                        <input
                          type="radio"
                          name="leadStatus"
                          data-name="leadStatus"
                          disabled={successMessage}
                          id="agency"
                          value="recruiter"
                          onChange={handleChange}
                          onBlur={handleValidation}
                        />
                        <span className="label-text">Recruitment Agency</span>
                      </label>
                    </div>
                    <div className="form-error">{formErrors?.leadStatus}</div>
                  </div>
                  <div className={`form-item form-textarea mt-4 ${getActiveClass('shareThought')}`}>
                    <label htmlFor="share-thought">
                      <div className="label-group">
                        Please share anything that will help prepare for our meeting.
                      </div>
                      <textarea
                        name="shareThought"
                        data-name="shareThought"
                        disabled={successMessage}
                        id="share-thought"
                        defaultValue={details.shareThought}
                        onChange={handleChange}
                        onBlur={handleValidation}
                        rows="3"
                      />
                      <div className="form-error">{formErrors?.shareThought}</div>
                    </label>
                  </div>
                  <div className={`form-item form-checkbox mt-4 ${getActiveClass('functionality')}`}>
                    <div className="label-group">What functionality are you most interested in?</div>

                    <div className="material-radio">
                      <label htmlFor="oneWay">
                        <input
                          type="radio"
                          name="functionality"
                          data-name="functionality"
                          disabled={successMessage}
                          value="One_Way_Recorded_Interviewing"
                          onChange={handleChange}
                          onBlur={handleValidation}
                          id="oneWay"
                        />
                        <span className="label-text">One Way Recorded Interviewing</span>
                      </label>
                    </div>
                    <div className="material-radio">
                      <label htmlFor="shortlisting">
                        <input
                          type="radio"
                          name="functionality"
                          data-name="functionality"
                          disabled={successMessage}
                          value="AI_Shortlisting"
                          onChange={handleChange}
                          onBlur={handleValidation}
                          id="shortlisting"
                        />
                        <span className="label-text">AI Shortlisting</span>
                      </label>
                    </div>
                    <div className="material-radio">
                      <label htmlFor="liveInterview">
                        <input
                          type="radio"
                          name="functionality"
                          data-name="functionality"
                          disabled={successMessage}
                          value="Two_Way_Live_Interviewing"
                          onChange={handleChange}
                          onBlur={handleValidation}
                          id="liveInterview"
                        />
                        <span className="label-text">Two Way Live Interviewing</span>
                      </label>
                    </div>
                    <div className="material-radio">
                      <label htmlFor="branding">
                        <input
                          type="radio"
                          name="functionality"
                          data-name="functionality"
                          disabled={successMessage}
                          value="Branding"
                          onChange={handleChange}
                          onBlur={handleValidation}
                          id="branding"
                        />
                        <span className="label-text">Branding</span>
                      </label>
                    </div>
                    <div className="material-radio">
                      <label htmlFor="videoQuestion">
                        <input
                          type="radio"
                          name="functionality"
                          data-name="functionality"
                          disabled={successMessage}
                          value="Video_Questions"
                          onChange={handleChange}
                          onBlur={handleValidation}
                          id="videoQuestion"
                        />
                        <span className="label-text">Video Questions</span>
                      </label>
                    </div>
                    <div className="material-radio">
                      <label htmlFor="integration">
                        <input
                          type="radio"
                          name="functionality"
                          data-name="functionality"
                          disabled={successMessage}
                          value="Integration_API"
                          onChange={handleChange}
                          onBlur={handleValidation}
                          id="integration"
                        />
                        <span className="label-text">Integration / API</span>
                      </label>
                    </div>
                    <div className="form-error">{formErrors?.functionality}</div>
                  </div>
                  <div className={`form-item form-radio mt-4 ${getActiveClass('begin')}`}>
                    <div className="label-group">When do you want to begin interviewing candidates?</div>

                    <div className="material-radio">
                      <label htmlFor="week">
                        <input
                          type="radio"
                          name="begin"
                          data-name="begin"
                          disabled={successMessage}
                          onChange={handleChange}
                          onBlur={handleValidation}
                          id="week"
                          value="This_week"
                        />
                        <span className="label-text">This week</span>
                      </label>
                    </div>
                    <div className="material-radio">
                      <label htmlFor="month">
                        <input
                          type="radio"
                          name="begin"
                          data-name="begin"
                          disabled={successMessage}
                          onChange={handleChange}
                          onBlur={handleValidation}
                          id="month"
                          value="This_Month"
                        />
                        <span className="label-text">This month</span>
                      </label>
                    </div>
                    <div className="material-radio">
                      <label htmlFor="more">
                        <input
                          type="radio"
                          name="begin"
                          data-name="begin"
                          disabled={successMessage}
                          onChange={handleChange}
                          onBlur={handleValidation}
                          id="more"
                          value="In_A_Month_Or_More"
                        />
                        <span className="label-text">In a month or more</span>
                      </label>
                    </div>
                    <div className="form-error">{formErrors?.begin}</div>
                  </div>
                  <div className="mt-4">&nbsp;</div>
                  <Button
                    className={`btn-cta ${isLoading ? 'loading' : ''}`}
                    text={data.button_first}
                    handleClick={handleSubmit}
                    disabled={!!successMessage}
                  />
                </form>
                {successMessage && (
                  <div className="success-message-wrapper">
                    <div className="success-message">
                      <span>Thanks!</span>
                      <p>
                        {`
                          We’re really looking forward to connecting. A member of the myInterview team will be in touch just as soon as we can.
                        `}
                      </p>
                    </div>
                    <div className="cartoon" />
                  </div>
                )}
              </div>
            </div>
            <div className="block-great col-12 d-lg-none mt-4">
              <div className="block-content">
                <div className="h2" dangerouslySetInnerHTML={{ __html: data.block_great_h2 }} />
                <div className="great-content">
                  <div className="great-row">
                    <div className="great-col-img">
                      <img src={starShield} alt="Free Forever" />
                    </div>
                    <div className="great-col-text">
                      <div className="great-title" dangerouslySetInnerHTML={{ __html: data.great_title }} />
                      <div className="great desc" dangerouslySetInnerHTML={{ __html: data.great_desc }} />
                    </div>
                  </div>
                  <div className="great-row">
                    <div className="great-col-img">
                      <img src={starValid} alt="Candidates love it" />
                    </div>
                    <div className="great-col-text">
                      <div className="great-title" dangerouslySetInnerHTML={{ __html: data.great_title_2 }} />
                      <div className="great desc" dangerouslySetInnerHTML={{ __html: data.great_desc_2 }} />
                    </div>
                  </div>
                  <div className="great-row">
                    <div className="great-col-img">
                      <img src={starChat} alt="Awesome Support" />
                    </div>
                    <div className="great-col-text">
                      <div className="great-title" dangerouslySetInnerHTML={{ __html: data.great_title_3 }} />
                      <div className="great desc" dangerouslySetInnerHTML={{ __html: data.great_desc_3 }} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="block-people col-12 d-lg-none">
            <div className="block-content">
              <div className="h2" dangerouslySetInnerHTML={{ __html: data.h2 }} />
              <div className="people-img mi-bg-gradient">
                <img src={employer} alt="Employer" className="img-employer" />
                <img src={logoPoferries} alt="P&O Ferries logo" className="img-logo-poferries" />
                <img src={arrowDown} alt="Arrow Down" className="img-arrow-down" />
              </div>
              <p className="quote" dangerouslySetInnerHTML={{ __html: data.quote }} />
              <div className="author" dangerouslySetInnerHTML={{ __html: data.author }} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
SectionOneForm.defaultProps = {
  data: {}
};
SectionOneForm.propTypes = {
  data: PropTypes.objectOf(PropTypes.any)
};
