import Swal from 'sweetalert2';
import 'sweetalert2/src/sweetalert2.scss';
import './index.scss';
import { getUtl } from '../../../../mixin/send-form';

export const tryNowClicked = () => Swal.fire({
  title: "Let's get started!",
  text: 'Enter your work email address to start video interviewing! (no credit card required)',
  input: 'text',
  inputPlaceholder: 'Enter your work email address',
  showCloseButton: true,
  confirmButtonText: 'Start',
  buttonsStyling: false,
  customClass: {
    container: 'container-class',
    popup: 'popup-class',
    header: 'header-class',
    title: 'title-class',
    confirmButton: 'confirm-button-class',
    content: 'content-class',
    input: 'input-class'
  }
}).then((result) => {
  if (result.isConfirmed) {
    // Category SIGNUP
    const BASE_URL = getUtl();
    let url = `https://dashboard.myinterview.com/${
      typeof result.value !== 'undefined'
        ? `signup?username=${result.value}`
        : ''}`;
    if (BASE_URL) {
      url = url + (url.indexOf('?') !== -1 ? '&' : '?') + BASE_URL;
    }
    window.open(url, '_blank');
  }
});
