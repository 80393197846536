import React from 'react';
import '../website.scoped.scss';

export const SectionFour = () => (
  <section id="numbers">
    <div className="container py-5 text-center">
      <div className="d-none d-lg-block">
        <p className="text-30 mb-0 text-panther">Tons of Views</p>
        <h2 className="font-weight-bolder text-uppercase text-panther display-6 mb-5">Tons of Results.</h2>
      </div>
      <div data-aos="zoom-in-up">
        <div className="bg-powder p-5 numbers-wrapper">
          <div className="row">
            <div className="col-md-6 col-xl-3">
              <div className="h3 font-weight-bolder text-panther display-6">
                2,000,000
                {/* <span style="font-size: 60%;">+</span> */}
              </div>
              <div className="spacer" />
              <p className="mb-0 font-weight-light first-line">
                Videos Processed
                <br />
                to Date
              </p>
            </div>
            <div className="col-md-6 col-xl-3">
              <div className="h3 font-weight-bolder text-panther display-6">91%</div>
              <div className="spacer" />
              <p className="mb-0 font-weight-light first-line">Satisfaction Rate</p>
            </div>
            <div className="col-md-6 col-xl-3">
              <div className="h3 font-weight-bolder text-panther display-6">60%</div>
              <div className="spacer" />
              <p className="mb-0 font-weight-light third-line">Faster Time to Hire</p>
            </div>
            <div className="col-md-6 col-xl-3">
              <div className="h3 font-weight-bolder text-panther display-6">71%</div>
              <div className="spacer" />
              <p className="mb-0 font-weight-light">
                Of Interviews Done in
                <br />
                Less Than 15 Mins
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);
