import React from 'react';
import Ell39 from '../../../theme/pictures/website/Ellipse39.svg';
import Ell38 from '../../../theme/pictures/website/Ellipse38.svg';
import HeroSm from '../../../theme/pictures/website/hero-sm.png';
import HeroMd from '../../../theme/pictures/website/hero-md.png';
import HeroLg from '../../../theme/pictures/website/hero-lg.png';
import '../website.scoped.scss';
import { tryNowClicked } from './sweetAlertComp/sweetAlert';

export const SectionOne = () => (
  <section id="hero">
    <div className="position-absolute hero-animated-1">
      <div data-aos="fade-up">
        <img src={Ell38} alt="" />
      </div>
    </div>
    <div className="position-absolute hero-animated-2">
      <div data-aos="fade-right">
        <img src={Ell39} alt="" />
      </div>
    </div>
    <div className="hero-container container h-100">
      <div className="row h-100">
        <div className="col-11 col-lg-5 align-self-end pb-xl-2 mb-2 mb-lg-5">
          <div className="bg-white hero-card py-3 py-xl-5 px-2 position-relative">
            <h1 className="text-gradient font-weight-bolder mb-2 mb-lg-4">
              MEET PEOPLE,
              <br />
              NOT RESUMES
            </h1>
            <p className="lead text-panther">Make It Personal With Smart Video Interviews</p>
            <p className="font-weight-light sub text-panther mb-2 mb-lg-4">A video is worth a thousand CVs. With myInterview, you easily get to know candidates and spotlight the perfect fit before they walk through the door.</p>
            <button type="button" className="btn btn-primary mt-3 btn-main" name="button" onClick={tryNowClicked}>Try For Free</button>
          </div>
        </div>
        <div className="col-12 col-lg-7 align-self-end">
          <picture>
            <source media="(max-width: 380px)" srcSet={HeroSm} />
            <source media="(max-width: 991px)" srcSet={HeroMd} />
            <img src={HeroLg} alt="MyInterview Banner" className="hero-img" />
          </picture>
        </div>
      </div>
    </div>
  </section>
);
