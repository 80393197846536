/* eslint-disable react/no-children-prop */
import React, { useEffect } from 'react';
import '../../theme/lp-1.scss';
import './bookademo.scoped.scss';
import PropTypes from 'prop-types';
import { getUtmParams, getCountryCode } from '../../mixin';
import Data from '../../data.json';

import {
  SectionOne, SectionTwo, SectionThree, SectionFour,
} from '../../components';

const LandingOne = ({ buttonAction }) => {
  let link = '';
  useEffect(async () => {
    link = await getCountryCode();
  });
  const utm = getUtmParams();
  const redirect = () => {
    window.location.href = `https://www.myinterview.com/bookademo${utm ? `?${utm}` : ''}`;
  };
  const popUp = () => {
    window.Calendly.showPopupWidget(`${link}${utm ? `?${utm}` : ''}`);
  };
  const handleButton = (label) => {
    if (buttonAction === 'calendly') {
      popUp();
      window.dataLayer = window.dataLayer ? window.dataLayer : [];
      window.dataLayer.push({ event: 'lp_bookademo', label: label === 'first' ? 'bookademo_first' : 'bookademo_second' });
    } else {
      window.dataLayer = window.dataLayer ? window.dataLayer : [];
      window.dataLayer.push({ event: 'lp_bookademo', label: label === 'first' ? 'bookademo_first' : 'bookademo_second' });
      redirect();
    }
  };
  return (
    <div className="lp-1 lp-book-demo wrapper">
      <SectionOne handleButton={handleButton} buttonAction={buttonAction} urm={utm} data={Data.bookademo[0]} />
      <SectionTwo data={Data.bookademo[0]} />
      <SectionThree data={Data.bookademo[0]} />
      <SectionFour handleButton={handleButton} buttonAction={buttonAction} urm={utm} data={Data.bookademo[0]} />
    </div>
  );
};

LandingOne.defaultProps = {
  buttonAction: 'calendly',
};
LandingOne.propTypes = {
  buttonAction: PropTypes.string,
};

export default LandingOne;
