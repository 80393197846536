import React from 'react';
import PropTypes from 'prop-types';
import './footer.scoped.scss';
import logo from '../../theme/pictures/mi-logo-white.svg';

export const Footer = ({ data }) => (
  <footer className="mi-bg-gradient">
    <div className="container">
      <div className="row footer-row">
        <div className="identity col-12 col-lg">
          <img src={logo} alt="Logo" className="logo" />
          <div dangerouslySetInnerHTML={{ __html: data.identity_div }} />
          <div dangerouslySetInnerHTML={{ __html: data.identity_div_2 }} />
        </div>
        <div className="copyrights col-12 col-lg">
          <div className="rights" dangerouslySetInnerHTML={{ __html: data.copyrights_rights }} />
          <div className="links">
            <a href="#test">{data.privacy_policy}</a>
            |
            <a href="#test">{data.terms_of_use}</a>
          </div>
        </div>
        <div className="origin col-12 col-lg">
          <p>
            {data.made_with}
            <svg className="heart" viewBox="0 0 32 29.6">
              <path d="M23.6,0c-3.4,0-6.3,2.7-7.6,5.6C14.7,2.7,11.8,0,8.4,0C3.8,0,0,3.8,0,8.4c0,9.4,9.5,11.9,16,21.2
c6.1-9.3,16-12.1,16-21.2C32,3.8,28.2,0,23.6,0z"
              />
            </svg>
            {data.made_with_2}
          </p>
        </div>
      </div>
    </div>
  </footer>
);

Footer.defaultProps = {
  data: {},
};
Footer.propTypes = {
  data: PropTypes.objectOf(PropTypes.any),
};
