import React from 'react';
import PropTypes from 'prop-types';
import employer from '../../theme/pictures/employer-1.png';
import logoPoferries from '../../theme/pictures/logo-poferries.png';
import arrowDown from '../../theme/pictures/arrow-down.png';
import starShield from '../../theme/pictures/star-shield.png';
import starValid from '../../theme/pictures/star-valid.png';
import starChat from '../../theme/pictures/star-chat.png';
import './section-two.scoped.scss';

export const SectionTwo = ({ data }) => (
  <div id="section-two" className="section-grey">
    <div className="container">
      <div className="row">
        <div className="block-people col-12 col-lg-6">
          <div className="block-content">
            <div className="h2" dangerouslySetInnerHTML={{ __html: data.h2 }} />
            <div className="people-img mi-bg-gradient">
              <img src={employer} alt="Employer" className="img-employer" />
              <img src={logoPoferries} alt="P&O Ferries logo" className="img-logo-poferries" />
              <img src={arrowDown} alt="Arrow Down" className="img-arrow-down" />
            </div>
            <p className="quote" dangerouslySetInnerHTML={{ __html: data.quote }} />
            <div className="author" dangerouslySetInnerHTML={{ __html: data.author }} />
          </div>
        </div>
        <div className="block-great col-12 col-lg-6">
          <div className="block-content mi-bg-gradient">
            <div className="h2" dangerouslySetInnerHTML={{ __html: data.block_great_h2 }} />
            <div className="great-content">
              <div className="great-row">
                <div className="great-col-img"><img src={starShield} alt="Free Forever" /></div>
                <div className="great-col-text">
                  <div className="great-title" dangerouslySetInnerHTML={{ __html: data.great_title }} />
                  <div className="great desc" dangerouslySetInnerHTML={{ __html: data.great_desc }} />

                </div>
              </div>
              <div className="great-row">
                <div className="great-col-img"><img src={starValid} alt="Candidates love it" /></div>
                <div className="great-col-text">
                  <div className="great-title" dangerouslySetInnerHTML={{ __html: data.great_title_2 }} />
                  <div className="great desc" dangerouslySetInnerHTML={{ __html: data.great_desc_2 }} />
                </div>
              </div>
              <div className="great-row">
                <div className="great-col-img"><img src={starChat} alt="Awesome Support" /></div>
                <div className="great-col-text">
                  <div className="great-title" dangerouslySetInnerHTML={{ __html: data.great_title_3 }} />
                  <div className="great desc" dangerouslySetInnerHTML={{ __html: data.great_desc_3 }} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

SectionTwo.defaultProps = {
  data: {},
};
SectionTwo.propTypes = {
  data: PropTypes.objectOf(PropTypes.any),
};
