import React from 'react';
import '../../theme/lp-1.scss';
import './form.scoped.scss';
import Data from '../../data.json';

import { SectionOneForm, SectionThreeForm, SectionFourForm } from '../../components';

const LandingOne = () => (
  <div className="lp-1 lp-demo-form wrapper">
    <SectionOneForm data={Data.demoplease[0]} />
    <SectionThreeForm data={Data.demoplease[0]} />
    <SectionFourForm data={Data.demoplease[0]} />
  </div>
);

LandingOne.defaultProps = {};
LandingOne.propTypes = {};

export default LandingOne;
