import React from 'react';
import './section-four.scoped.scss';
import PropTypes from 'prop-types';
import { Button } from '../button';
import cartoon from '../../theme/pictures/cartoon.png';

export const SectionFour = ({ handleButton, data }) => (
  <div id="section-four">
    <div className="container">
      <div className="row">
        <div className="block-free col-12 col-lg-6">
          <div className="h2" dangerouslySetInnerHTML={{ __html: data.section_four_h2 }} />
          <p dangerouslySetInnerHTML={{ __html: data.section_four_p }} />
          <Button className="mt-3 btn-cta" text={data.button_second} handleClick={() => handleButton('second')} />
        </div>
        <div className="block-empty col-12 col-lg-6">
          <img src={cartoon} alt="Cartoon" className="img-cartoon" />
        </div>
      </div>
    </div>
  </div>
);
SectionFour.defaultProps = {
  handleButton: () => {},
  data: {},
};
SectionFour.propTypes = {
  handleButton: PropTypes.func,
  data: PropTypes.objectOf(PropTypes.any),

};
