/* eslint-disable */
export default {
  countries: {
    "AU": "https://calendly.com/myinterview/request-a-demo-with-our-dream-team-apac",
    "NZ": "https://calendly.com/myinterview/request-a-demo-with-our-dream-team-apac",
    "IN": "https://calendly.com/myinterview/request-a-demo-with-our-dream-team-south-asia",
    "KR": "https://calendly.com/myinterview/request-a-demo-with-our-dream-team-apac",
    "HK": "https://calendly.com/myinterview/request-a-demo-with-our-dream-team-apac",
    "SG": "https://calendly.com/myinterview/request-a-demo-with-our-dream-team-apac",
    "MY": "https://calendly.com/myinterview/request-a-demo-with-our-dream-team-apac",
    "CN": "https://calendly.com/myinterview/request-a-demo-with-our-dream-team-apac",
    "US": "https://calendly.com/myinterview/request-a-demo-with-our-dream-team-us",
    "CA": "https://calendly.com/myinterview/request-a-demo-with-our-dream-team-us",
    "MX": "https://calendly.com/myinterview/request-a-demo-with-our-dream-team-us",
    "GB": "https://calendly.com/myinterview/request-a-demo-with-our-dream-team-europe",
    "IE": "https://calendly.com/myinterview/request-a-demo-with-our-dream-team-europe",
    "AT": "https://calendly.com/myinterview/request-a-demo-with-our-dream-team-europe",
    "BE": "https://calendly.com/myinterview/request-a-demo-with-our-dream-team-europe",
    "CZ": "https://calendly.com/myinterview/request-a-demo-with-our-dream-team-europe",
    "DK": "https://calendly.com/myinterview/request-a-demo-with-our-dream-team-europe",
    "EE": "https://calendly.com/myinterview/request-a-demo-with-our-dream-team-europe",
    "FI": "https://calendly.com/myinterview/request-a-demo-with-our-dream-team-europe",
    "FR": "https://calendly.com/myinterview/request-a-demo-with-our-dream-team-europe",
    "DE": "https://calendly.com/myinterview/request-a-demo-with-our-dream-team-europe",
    "GR": "https://calendly.com/myinterview/request-a-demo-with-our-dream-team-europe",
    "HU": "https://calendly.com/myinterview/request-a-demo-with-our-dream-team-europe",
    "IS": "https://calendly.com/myinterview/request-a-demo-with-our-dream-team-europe",
    "IT": "https://calendly.com/myinterview/request-a-demo-with-our-dream-team-europe",
    "IL": "https://calendly.com/myinterview/request-a-demo-with-our-dream-team-europe",
    "LV": "https://calendly.com/myinterview/request-a-demo-with-our-dream-team-europe",
    "LI": "https://calendly.com/myinterview/request-a-demo-with-our-dream-team-europe",
    "LT": "https://calendly.com/myinterview/request-a-demo-with-our-dream-team-europe",
    "LU": "https://calendly.com/myinterview/request-a-demo-with-our-dream-team-europe",
    "MT": "https://calendly.com/myinterview/request-a-demo-with-our-dream-team-europe",
    "NL": "https://calendly.com/myinterview/request-a-demo-with-our-dream-team-europe",
    "NO": "https://calendly.com/myinterview/request-a-demo-with-our-dream-team-europe",
    "PL": "https://calendly.com/myinterview/request-a-demo-with-our-dream-team-europe",
    "PT": "https://calendly.com/myinterview/request-a-demo-with-our-dream-team-europe",
    "SK": "https://calendly.com/myinterview/request-a-demo-with-our-dream-team-europe",
    "SI": "https://calendly.com/myinterview/request-a-demo-with-our-dream-team-europe",
    "ES": "https://calendly.com/myinterview/request-a-demo-with-our-dream-team-europe",
    "SE": "https://calendly.com/myinterview/request-a-demo-with-our-dream-team-europe",
    "CH": "https://calendly.com/myinterview/request-a-demo-with-our-dream-team-europe",
    "AL": "https://calendly.com/myinterview/request-a-demo-with-our-dream-team-europe",
    "ME": "https://calendly.com/myinterview/request-a-demo-with-our-dream-team-europe",
    "MK": "https://calendly.com/myinterview/request-a-demo-with-our-dream-team-europe",
    "RS": "https://calendly.com/myinterview/request-a-demo-with-our-dream-team-europe",
    "TR": "https://calendly.com/myinterview/request-a-demo-with-our-dream-team-europe",
    "AD": "https://calendly.com/myinterview/request-a-demo-with-our-dream-team-europe",
    "AM": "https://calendly.com/myinterview/request-a-demo-with-our-dream-team-europe",
    "AZ": "https://calendly.com/myinterview/request-a-demo-with-our-dream-team-europe",
    "BY": "https://calendly.com/myinterview/request-a-demo-with-our-dream-team-europe",
    "GE": "https://calendly.com/myinterview/request-a-demo-with-our-dream-team-europe",
    "MD": "https://calendly.com/myinterview/request-a-demo-with-our-dream-team-europe",
    "MC": "https://calendly.com/myinterview/request-a-demo-with-our-dream-team-europe",
    "RU": "https://calendly.com/myinterview/request-a-demo-with-our-dream-team-europe",
    "SM": "https://calendly.com/myinterview/request-a-demo-with-our-dream-team-europe",
    "UA": "https://calendly.com/myinterview/request-a-demo-with-our-dream-team-europe",
    "ET": "https://calendly.com/myinterview/request-a-demo-with-our-dream-team-africa",
    "EG": "https://calendly.com/myinterview/request-a-demo-with-our-dream-team-africa",
    "CD": "https://calendly.com/myinterview/request-a-demo-with-our-dream-team-africa",
    "CG": "https://calendly.com/myinterview/request-a-demo-with-our-dream-team-africa",
    "TZ": "https://calendly.com/myinterview/request-a-demo-with-our-dream-team-africa",
    "ZA": "https://calendly.com/myinterview/request-a-demo-with-our-dream-team-africa",
    "KE": "https://calendly.com/myinterview/request-a-demo-with-our-dream-team-africa",
    "UG": "https://calendly.com/myinterview/request-a-demo-with-our-dream-team-africa",
    "DZ": "https://calendly.com/myinterview/request-a-demo-with-our-dream-team-africa",
    "SS": "https://calendly.com/myinterview/request-a-demo-with-our-dream-team-africa",
    "SD": "https://calendly.com/myinterview/request-a-demo-with-our-dream-team-africa",
    "MA": "https://calendly.com/myinterview/request-a-demo-with-our-dream-team-africa",
    "AO": "https://calendly.com/myinterview/request-a-demo-with-our-dream-team-africa",
    "MZ": "https://calendly.com/myinterview/request-a-demo-with-our-dream-team-africa",
    "GH": "https://calendly.com/myinterview/request-a-demo-with-our-dream-team-africa",
    "MG": "https://calendly.com/myinterview/request-a-demo-with-our-dream-team-africa",
    "CM": "https://calendly.com/myinterview/request-a-demo-with-our-dream-team-africa",
    "CI": "https://calendly.com/myinterview/request-a-demo-with-our-dream-team-africa",
    "NE": "https://calendly.com/myinterview/request-a-demo-with-our-dream-team-africa",
    "NG": "https://calendly.com/myinterview/request-a-demo-with-our-dream-team-africa",
    "BF": "https://calendly.com/myinterview/request-a-demo-with-our-dream-team-africa",
    "ML": "https://calendly.com/myinterview/request-a-demo-with-our-dream-team-africa",
    "MW": "https://calendly.com/myinterview/request-a-demo-with-our-dream-team-africa",
    "ZM": "https://calendly.com/myinterview/request-a-demo-with-our-dream-team-africa",
    "SN": "https://calendly.com/myinterview/request-a-demo-with-our-dream-team-africa",
    "TD": "https://calendly.com/myinterview/request-a-demo-with-our-dream-team-africa",
    "SO": "https://calendly.com/myinterview/request-a-demo-with-our-dream-team-africa",
    "ZW": "https://calendly.com/myinterview/request-a-demo-with-our-dream-team-africa",
    "GN": "https://calendly.com/myinterview/request-a-demo-with-our-dream-team-africa",
    "PG": "https://calendly.com/myinterview/request-a-demo-with-our-dream-team-africa",
    "CQ": "https://calendly.com/myinterview/request-a-demo-with-our-dream-team-africa",
    "RW": "https://calendly.com/myinterview/request-a-demo-with-our-dream-team-africa",
    "BJ": "https://calendly.com/myinterview/request-a-demo-with-our-dream-team-africa",
    "BI": "https://calendly.com/myinterview/request-a-demo-with-our-dream-team-africa",
    "TN": "https://calendly.com/myinterview/request-a-demo-with-our-dream-team-africa",
    "TG": "https://calendly.com/myinterview/request-a-demo-with-our-dream-team-africa",
    "SL": "https://calendly.com/myinterview/request-a-demo-with-our-dream-team-africa",
    "LY": "https://calendly.com/myinterview/request-a-demo-with-our-dream-team-africa",
    "LR": "https://calendly.com/myinterview/request-a-demo-with-our-dream-team-africa",
    "CF": "https://calendly.com/myinterview/request-a-demo-with-our-dream-team-africa",
    "MR": "https://calendly.com/myinterview/request-a-demo-with-our-dream-team-africa",
    "ER": "https://calendly.com/myinterview/request-a-demo-with-our-dream-team-africa",
    "NA": "https://calendly.com/myinterview/request-a-demo-with-our-dream-team-africa",
    "GM": "https://calendly.com/myinterview/request-a-demo-with-our-dream-team-africa",
    "BW": "https://calendly.com/myinterview/request-a-demo-with-our-dream-team-africa",
    "GA": "https://calendly.com/myinterview/request-a-demo-with-our-dream-team-africa",
    "LS": "https://calendly.com/myinterview/request-a-demo-with-our-dream-team-africa",
    "GW": "https://calendly.com/myinterview/request-a-demo-with-our-dream-team-africa",
    "MU": "https://calendly.com/myinterview/request-a-demo-with-our-dream-team-africa",
    "SZ": "https://calendly.com/myinterview/request-a-demo-with-our-dream-team-africa",
    "DJ": "https://calendly.com/myinterview/request-a-demo-with-our-dream-team-africa",
    "KM": "https://calendly.com/myinterview/request-a-demo-with-our-dream-team-africa",
    "CV": "https://calendly.com/myinterview/request-a-demo-with-our-dream-team-africa",
    "ST": "https://calendly.com/myinterview/request-a-demo-with-our-dream-team-africa",
    "SC": "https://calendly.com/myinterview/request-a-demo-with-our-dream-team-africa"
  }
}
