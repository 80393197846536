import React, { useState, useEffect } from 'react';
import { SectionOne } from './components/sectionOne';
import { SwiperSlider } from './components/sectionTwo';
import { SectionFour } from './components/sectionFour';
import { SectionSteps } from './components/SectionSteps';
import { SectionCta } from './components/SectionCta';
import { SectionCtaCard } from './components/SectionCtaCard';
import { SectionVideo } from './components/SectionVideo';
import { SectionTestimonials } from './components/SectionTestimonials';
import { SectionTeam } from './components/SectionTeam';
import { SectionPlans } from './components/SectionPlans';
import { SectionPartners } from './components/SectionPartners';
import { SectionNews } from './components/SectionNews';
import { SectionFooter } from './components/SectionFooter';
import './website.scoped.scss';
// eslint-disable-next-line import/order
import AOS from 'aos';
import 'aos/dist/aos.css';
import { tryNowClicked } from './components/sweetAlertComp/sweetAlert';

const WebSite = () => {
  const [showButton, setShowButton] = useState(false);
  const onScroll = () => {
    const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop;
    if (currentScrollPosition > 350) {
      setShowButton(true);
    } else {
      setShowButton(false);
    }
  };
  useEffect(() => {
    AOS.init({
      duration: 1000
    });
  }, []);
  useEffect(() => {
    window.addEventListener('scroll', onScroll);
    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, []);
  return (
    <div className="page-home">
      <SectionOne />
      <section id="partners">
        <div className="container py-5">
          <SwiperSlider />
        </div>
      </section>
      <SectionFour />
      <SectionSteps />
      <SectionCta />
      <SectionCtaCard />
      <div className="empty-space" />
      <SectionVideo />
      <div className="empty-space" />
      <SectionTestimonials />
      <SectionTeam />
      <div className="empty-space" />
      <SectionPlans />
      <SectionPartners />
      <div className="empty-space" />
      <SectionNews />
      <div className="empty-space" />
      <SectionFooter />
      <div className={`floating-button ${showButton && 'el-visible'}`}>
        <button
          type="button"
          className="btn btn-primary mt-3"
          name="button"
          onClick={tryNowClicked}
        >
          Try For Free
        </button>
      </div>
    </div>
  );
};

export default WebSite;
