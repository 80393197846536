import axios from 'axios';
import { getUtmParams } from '.';

export const getUtl = () => {
  if (process.env.REACT_APP_SECRET_CODE === 'prod') return 'https://api-landing-website.myinterview.com/sendDataToHubspot';
  return 'http://localhost:8000/sendDataToHubspot';
};

const BASE_URL = getUtl();

export const sendForm = async (form) => {
  const { shareThought, functionality, begin } = form;
  const message = `message: ${shareThought} | functionality: ${functionality} | begin: ${begin}`;
  const data = { ...form, source: 'demoform', message, convButton: 'demoform' };
  delete data.shareThought;
  delete data.functionality;
  delete data.begin;
  const utmParams = getUtmParams();
  if (utmParams) {
    data.utm_params = utmParams;
  }
  const res = await axios.post(BASE_URL, { data });
  return res;
};
