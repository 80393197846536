import React from 'react';
import '../website.scoped.scss';
import { tryNowClicked } from './sweetAlertComp/sweetAlert';

export const SectionFooter = () => (
  <section id="footer-cta">
    <div className="container pb-5">
      <div data-aos="zoom-in-up">
        <div className="bg-white text-center cta-card p-5">
          <h2 className="font-weight-bolder text-panther display-5">
            MEET YOUR
            <br />
            NEXT STAR HIRE
          </h2>
          <p className="cta-text text-panther arrow-after-right font-notes">
            No credit card required
          </p>
          <button
            type="button"
            className="btn bg-apple rounded-pill text-white"
            href="#"
            name="button"
            onClick={tryNowClicked}
          >
            Try Now for Free
          </button>
        </div>
      </div>
    </div>
  </section>
);
