import React from 'react';
import './section-four.scoped.scss';
import PropTypes from 'prop-types';
import { Button } from '../button';

export const SectionFourForm = ({ handleButton, data }) => (
  <div id="section-four">
    <div className="container">
      <div className="row">
        <div className="block-free col-12 col-lg-6">
          <div className="h2" dangerouslySetInnerHTML={{ __html: data.section_four_h2 }} />
          <p dangerouslySetInnerHTML={{ __html: data.section_four_p }} />
          <Button
            className="d-none mt-3 btn-cta"
            text={data.button_second}
            handleClick={() => handleButton('second')}
          />
        </div>
        <div className="block-empty col-12 col-lg-6">&nbsp;</div>
      </div>
    </div>
  </div>
);
SectionFourForm.defaultProps = {
  handleButton: () => {},
  data: {}
};
SectionFourForm.propTypes = {
  handleButton: PropTypes.func,
  data: PropTypes.objectOf(PropTypes.any)
};
