import React from 'react';
import '../website.scoped.scss';
import { tryNowClicked } from './sweetAlertComp/sweetAlert';

export const SectionCtaCard = () => (
  <section id="cta-card">
    <div className="container pb-5">
      <div className="bg-white text-center cta-card p-5">
        <h2 className="font-weight-bolder text-panther display-5 mt-4">
          MEET YOUR
          <br />
          NEXT STAR HIRE
        </h2>
        <p className="cta-text text-panther arrow-after-right mb-4 font-notes">
          No credit card required
        </p>
        <button
          type="button"
          className="btn bg-apple rounded-pill text-white"
          name="button"
          onClick={tryNowClicked}
        >
          Try Now for Free
        </button>
      </div>
      <div className="spacer-mobile d-block d-lg-none" />
    </div>
  </section>
);
