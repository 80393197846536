import React from 'react';
import InterviewCrtoon from '../../../theme/pictures/website/Interview-Crtoon.png';
import teamSm from '../../../theme/pictures/website/team-sm.png';
import teamLg from '../../../theme/pictures/website/team-lg.png';
import Star6 from '../../../theme/pictures/website/star-icon-(6).svg';
import Star5 from '../../../theme/pictures/website/star-icon-(5).svg';
import Star4 from '../../../theme/pictures/website/star-icon-(4).svg';
import Star3 from '../../../theme/pictures/website/star-icon-(3).svg';
import Star2 from '../../../theme/pictures/website/star-icon-(2).svg';
import Star1 from '../../../theme/pictures/website/star-icon-(1).svg';
import '../website.scoped.scss';

export const SectionTeam = () => (
  <section id="team">
    <div className="position-absolute team-static-icon">
      <img src={InterviewCrtoon} className="d-none d-lg-block" alt="" loading="lazy" />
    </div>
    <div className="container text-center py-5">
      <picture>
        <source media="(max-width: 991px)" srcSet={teamSm} />
        <img src={teamLg} alt="Enterprise Grade banner" className="mb-5" loading="lazy" />
      </picture>
      <h2 className="text-uppercase font-weight-bolder text-panther display-6 mb-5">
        Enterprise Grade.
        <br />
        Except for
        the Price.
      </h2>
      <div className="row">
        <div className="col-lg-8 mx-auto mb-5">
          <p className="text-panther lead">
            Don’t let free fool you. myInterview packs powerful features that
            enterprises can rely on, all at a price any sized company can
            afford. That’s why both Fortune 500s like Facebook, Six Flags and
            Chick-fil-A, as well as hundreds of small businesses already trust
            us to transform the way they hire.
          </p>
        </div>
      </div>
      <div id="stars" className="row gx-0 align-items-end">
        <div className="col-md-6">
          <div className="row gx-0 align-items-end">
            <div className="col-4">
              <img
                src={Star6}
                className="img-fluid d-block mx-auto mb-3 smaller-star"
                alt=""
                loading="lazy"
              />
              <div className="h5">24/7 Support</div>
            </div>
            <div className="col-4 order-last ">
              <img
                src={Star5}
                className="img-fluid d-block mx-auto mb-3 smaller-star"
                alt=""
                loading="lazy"
              />
              <div className="h5">Security</div>
            </div>
            <div className="col-4 order-md-last">
              <img
                src={Star4}
                className="img-fluid d-block mx-auto mb-3"
                alt=""
                loading="lazy"
              />
              <div className="h5">Compliance</div>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="row gx-0 align-items-end">
            <div className="col-4">
              <img
                src={Star3}
                className="img-fluid d-block mx-auto mb-3"
                alt=""
                loading="lazy"
              />
              <div className="h5">Integrations</div>
            </div>
            <div className="col-4">
              <img
                src={Star2}
                className="img-fluid d-block mx-auto mb-3 smaller-star"
                alt=""
                loading="lazy"
              />
              <div className="h5">All Devices</div>
            </div>
            <div className="col-4 order-first order-md-last">
              <img
                src={Star1}
                className="img-fluid d-block mx-auto mb-3 smaller-star"
                alt=""
                loading="lazy"
              />
              <div className="h5">Advanced Analytics</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);
