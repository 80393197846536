import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import Techcrunch from '../../../theme/pictures/website/logos/techcrunch_logo.png';
import Forbes from '../../../theme/pictures/website/logos/forbes_logo.png';
import HrDailyAdvisor from '../../../theme/pictures/website/logos/hr-daily-advisor-logo.png';
import RecruitingDaily from '../../../theme/pictures/website/logos/recruiting-daily-logo.png';
import '../website.scoped.scss';

export const SectionNews = () => {
  const config = {
    centeredSlides: true,
    // spaceBetween: 80,
    // loop: true,
    // speed: 3500,
    // autoplay: {
    //   delay: 0,
    //   disableOnInteraction: false,
    // },
    breakpoints: {
      640: {
        slidesPerView: 1
      },
      768: {
        slidesPerView: 1
      },
      1024: {
        slidesPerView: 2
      },
      1600: {
        slidesPerView: 4
      }
    }
  };

  return (
    <section id="news">
      <div className="container-fluid py-5">
        <h2 className="text-center font-weight-bolder text-uppercase text-panther display-6 mb-4">In the News</h2>
        <Swiper
          {...config}
        >
          <SwiperSlide>
            <div className="bg-white w-50 mx-auto position-relative p-4 text-center border-20 testimonial-arrow">
              <a
                href="#/lol"
              >
                <img src={Techcrunch} alt="" loading="lazy" />
              </a>
            </div>
            <div className="mi-bg-gradient testimonial-card border-30 text-white text-center">
              <p>
                &quot;By automatically creating candidate shortlists focused on workplace culture compatibility,
                myInterview&quot;s machine learning-based tools can help recruiters surface applicants who might otherwise
                be overlooked.&quot;
              </p>
              <p className="mb-0 testimonial-name">TechCrunch</p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="bg-white w-50 mx-auto position-relative p-4 text-center border-20 testimonial-arrow">
              <a
                href="#/lol"
              >
                <img src={Forbes} alt="" loading="lazy" />
              </a>
            </div>
            <div className="mi-bg-gradient testimonial-card border-30 text-white text-center">
              <p>
                &quot;When done well, video interviewing can become a true strategic advantage for employers—and a
                formidable ally as you compete in the war for talent.&quot;
              </p>
              <p className="mb-0 testimonial-name">Forbes</p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="bg-white w-50 mx-auto position-relative p-4 text-center border-20 testimonial-arrow">
              <a
                href="#/lol"
              >
                <img src={HrDailyAdvisor} alt="" loading="lazy" />
              </a>
            </div>
            <div className="mi-bg-gradient testimonial-card border-30 text-white text-center">
              <p>
                While video interviews were hardly unheard of before the pandemic, many businesses had little to no experience meeting job candidates on screen. One year on, they’re seeing substantial long-term benefits to the practice.
              </p>
              <p className="mb-0 testimonial-name">HR Daily Advisor</p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="bg-white w-50 mx-auto position-relative p-4 text-center border-20 testimonial-arrow">
              <a
                href="#/lol"
              >
                <img src={RecruitingDaily} alt="" loading="lazy" />
              </a>
            </div>
            <div className="mi-bg-gradient testimonial-card border-30 text-white text-center">
              <p>
                &quot;If the point of a resume is to provide a summary of professional experience and achievements, why not allow the candidate to speak for themselves and show us who they really are, personality and all?&quot;
              </p>
              <p className="mb-0 testimonial-name">Recruiting Daily</p>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </section>
  );
};
