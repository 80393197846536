import React from 'react';
import PropTypes from 'prop-types';
import './button.scoped.scss';

export const Button = ({ text, className, handleClick, type, disabled }) => (
  <button type={type} className={className} onClick={handleClick} disabled={disabled}>
    <span className="button-text">{text}</span>
  </button>
);

Button.defaultProps = {
  text: 'Button',
  className: '',
  handleClick: () => {},
  type: 'button',
  disabled: false
};
Button.propTypes = {
  text: PropTypes.string,
  className: PropTypes.string,
  handleClick: PropTypes.func,
  type: PropTypes.string,
  disabled: PropTypes.bool
};
