import React from 'react';
import './section-three.scoped.scss';
import {
  SwiperSlider,
} from '../swiper';

export const SectionThree = () => (
  <div id="section-three">
    <div className="container">
      <div className="row">
        <div className="block-slider col-12 col-lg-6">
          <SwiperSlider />
        </div>
        <div className="block-empty col-12 col-lg-6 d-none d-lg-flex">
          <div className="mi-bg-gradient">&nbsp;</div>
        </div>
      </div>
    </div>
  </div>
);
