import React from 'react';
import ELL42 from '../../../theme/pictures/website/Ellipse42.svg';
import ELL43 from '../../../theme/pictures/website/Ellipse43.svg';
import '../website.scoped.scss';

export const SectionPlans = () => (
  <section id="plans" className="bg-powder py-3">
    <div className="position-absolute plans-animated-1 d-none d-lg-block">
      <div data-aos="fade-down">
        <img src={ELL42} alt="" />
      </div>
    </div>
    <div className="position-absolute plans-animated-2">
      <div data-aos="fade-left">
        <img src={ELL43} alt="" />
      </div>
    </div>
    <div className="container">
      <div className="row align-items-center">
        <div className="col-lg-6">
          <h2 className="text-uppercase font-weight-bolder text-panther display-6 text-center text-lg-start">TOP Plans</h2>
          <p className="text-black font-weight-bold text-20 d-none d-lg-block">
            Compare our plans
            <br />
            side by side
            <i className="fas fa-greater-than text-apple ms-2" />
          </p>
          <p className="text-black font-weight-bold text-20 text-center d-block d-lg-none">
            Compare our plans side by side
            <i className="fas fa-greater-than text-apple ms-2" />
          </p>
        </div>
        <div className="col-lg-6">
          <div id="vertical" className="price-container">
            <div className="price-wrapper">
              <div className="price-slide price-slide-starters">
                <div className="bg-white p-4 border-20 d-flex flex-column align-items-center justify-content-center">
                  <a href="#/lol">
                    <h2 className="text-panther text-uppercase font-weight-bolder my-0">
                      <span className="plans-cta text-capitalize me-auto price-right-arrow">
                        Right for
                      </span
                        >
                      <br />
                      <span className="display-5">STARTERS</span>
                    </h2>
                    <div className="h5 font-weight-bold text-panther text-center">
                      USD
                      <span className="text-apple">$0</span>
                      |
                      <small>Free Forever</small>
                    </div>
                  </a>
                </div>
              </div>
              <div className="price-slide price-slide-growth">
                <div
                  className="bg-white p-4 border-20 d-flex flex-column align-items-center justify-content-center"
                >
                  <a href="#/lol">
                    <h2
                      className="text-panther text-uppercase font-weight-bolder my-0"
                    >
                      <span
                        className="plans-cta text-capitalize me-auto price-left-arrow"
                      >
                        Time for
                      </span
                        >
                      <br />
                      <span className="display-5">Growth</span>
                    </h2>
                    <div className="h5 font-weight-bold text-panther text-center">
                      USD
                      <span className="text-apple">
                        $29
                        {/* <span style="font-size: 12px"> / month</span> */}
                        <span> / month</span>
                      </span
                        >
                      |
                      <small>Billed Yearly</small>
                    </div>
                  </a>

                </div>
              </div>
              <div className="price-slide price-slide-team">
                <div
                  className="bg-white p-4 border-20 d-flex flex-column align-items-center justify-content-center"
                >
                  <a href="#/lol">
                    <h2
                      className="text-panther text-uppercase font-weight-bolder my-0"
                    >
                      <span
                        className="plans-cta text-capitalize me-auto price-left-arrow"
                      >
                        Going
                      </span
                        >
                      <br />
                      <span className="display-5">TEAM</span>
                    </h2>
                    <div className="h5 font-weight-bold text-panther text-center">
                      USD
                      <span className="text-apple">
                        $85
                        {/* <span style="font-size: 12px;"> / month</span> */}
                        <span> / month</span>
                      </span
                        >
                      |
                      <small>Billed Yearly</small>
                    </div>
                  </a>
                </div>
              </div>
              <div className="price-slide price-slide-performance">
                <div
                  className="bg-white p-4 border-20 d-flex flex-column align-items-center justify-content-center"
                >
                  <a href="#/lol">
                    <h2
                      className="text-panther text-uppercase font-weight-bolder my-0"
                    >
                      <span
                        className="plans-cta text-capitalize me-auto price-angle-arrow"
                      >
                        Let’s get
                      </span
                        >
                      <br />
                      <span className="display-5">PERFORMANCE</span>
                    </h2>
                    <div className="h5 font-weight-bold text-panther text-center">
                      USD
                      <span className="text-apple">
                        $225
                        {/* <span style="font-size: 12px;"> / month</span> */}
                        <span> / month</span>
                      </span
                        >
                      |
                      <small>Billed Yearly</small>
                    </div>
                  </a>
                </div>
              </div>
              <div className="price-slide price-slide-enterprise">
                <div
                  className="bg-white p-4 border-20 d-flex flex-column align-items-center justify-content-center"
                >
                  <a href="#/lol">
                    <h2
                      className="text-panther text-uppercase font-weight-bolder my-0"
                    >
                      <span
                        className="plans-cta text-capitalize me-auto price-angle-arrow"
                      >
                        Built for
                      </span
                        >
                      <br />
                      <span className="display-5">enterprise</span>
                    </h2>
                    <div className="h5 font-weight-bold text-panther text-center">
                      <span className="text-apple">Contact us</span>
                      |
                      <small>Flexible Plans</small>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);
